.accordion {
  border: solid rgb(229, 231, 235) 1px;
  border-radius: 10px;
  font-family: 'FH Oscar';
  transition: all 0.2s ease-out;
  background-color: white;
  margin-bottom: 1rem;
}

.accordion .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.2s ease-out;
  align-items: center;
  padding: 1rem;
}

.accordion .content {
  transition: all 0.2s ease-out;
  max-height: 0;
  padding: 0 1rem;
}

.accordion.expanded .content {
  max-height: inherit;
  margin-bottom: 1rem;
}

.accordion .icon {
  transition: all 0.2s ease-out;
  width: 16px;
  height: 10px;
}

.accordion.expanded .icon {
  transform: rotate(180deg);
}

